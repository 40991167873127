@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap");

@import "./styles/reset.scss";

$grey-750: #d7d7d7;
$grey-100: #262626;
$red: #ff6464;

$content-max-width: 600px;

html {
  font-size: 62.5%;
}

body {
  font-family: Ubuntu, "Avenir Next", sans-serif;
  color: $grey-100;
  font-size: 1.6rem;
  font-weight: 300;
}

a {
  color: $grey-100;
  text-decoration: none;
  font-weight: 500;

  &:hover,
  &:focus {
    outline: 0;
    color: $red;
    opacity: 0.5;
  }
}

.active {
  color: $red;
}

.navbar {
  border-top: 1px solid $grey-750;
  height: 6rem;

  ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    height: 100%;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 6.5rem;
      font-size: 1rem;
      text-transform: uppercase;

      svg {
        margin-bottom: 0.5rem;
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .sorting-dialog {
    position: absolute;
    transform: translate(-50%, -100%);
    top: 0;
    left: 50%;
    margin: -1.5rem 0;
    border: 1px dotted $grey-750;
    border-radius: 25rem;
    display: flex;
    justify-content: center;

    button {
      border: none;
      background-color: white;

      &:hover,
      &:focus {
        outline: 0;
        color: $red;
        opacity: 0.5;
      }
    }
  }

  @media (min-width: $content-max-width) {
    border: none;

    li {
      padding: 0 1rem;
    }
  }
}

.navbar-button {
  button {
    border: none;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 6.5rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    &:hover,
    &:focus {
      outline: 0;
      color: $red;
      opacity: 0.5;
    }
  }
  svg {
    margin-bottom: 0.5rem;
    width: 2rem;
    height: 2rem;
  }
}

.main-grid {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  max-width: $content-max-width;
  margin: auto;

  header {
    height: 7rem;
    flex-shrink: 0;
  }

  main {
    flex: 1 1 auto;
    overflow-y: auto;
  }

  footer {
    flex-shrink: 0;
    height: 6rem;
  }

  @media (min-width: $content-max-width) {
    display: block;

    header {
      width: $content-max-width;
      border-radius: 25rem;
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 2.5rem;
      z-index: 2;
    }

    main {
      padding: 8rem 3.5rem;
      position: relative;
      z-index: 1;
    }

    footer {
      border-radius: 25rem;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
      position: fixed;
      bottom: 1.5rem;
      left: 50%;
      transform: translateX(-50%);
      padding: 0 2.5rem;
      z-index: 2;
    }
  }
}

button {
  font-size: 1.4rem;

  &.button-link {
    border: none;
    font-size: 1.7rem;
    background-color: white;
    margin-top: 10px;
    text-decoration: underline;
    width: 100%;
  }
}

.button-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

button,
input {
  &:focus {
    outline: 0;
    box-shadow: 0 0 1px $red;
  }
}

input {
  width: 100%;
  min-width: 100px;
  font-size: 1.6rem;
}

.feather {
  width: 2rem;
  height: 2rem;
}

.search-input {
  display: flex;
  margin: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 25rem;

  input {
    flex: 1 1 auto;
    border-top-left-radius: 25rem;
    border-bottom-left-radius: 25rem;
    border: 0;
    padding-left: 1.5rem;
    margin-right: 0.5rem;
  }

  button {
    border-radius: 100%;
    flex-shrink: 0;
    width: 4rem;
    height: 4rem;
    border: 0.5rem solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: $red;
    color: white;
    cursor: pointer;
  }
}

.item-list {
  padding: 0 1.5rem !important;

  li {
    display: flex;
    margin: 1.5rem 0;
    align-items: center;

    .title {
      flex: 1 1 auto;
      border: 0;
      font-size: 1.6rem;
    }

    button {
      background: transparent;
      border: 0;
    }

    > span {
      flex-shrink: 0;
    }

    .trash {
      color: $red;
    }
  }

  .checkbox {
    flex-shrink: 0;
    border: 2px solid $grey-750;
    margin-right: 1.5rem;
    border-radius: 100%;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      border-color: $red;
      background: $red;
      color: white;
    }

    input {
      display: none;
    }
  }
}
.category-title {
  display: flex;
  margin: 1.5rem 0;
  justify-content: center;
  opacity: 0.5;
  align-items: center;

  span {
    font-size: 1.6rem;
    font-weight: 500;
  }
}

.alert {
  span {
    display: flex;
    margin: 1.5rem 0;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 500;
    color: $red;
  }
  .button-group {
    display: flex;
    margin: 3rem 0;
    justify-content: space-evenly;
    align-items: center;
  }
  button {
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 2px solid $grey-750;
    width: 100px;
    border-radius: 25rem;
  }
}

.login-form {
  margin-top: 2rem;

  label {
    color: $grey-100;
  }

  input {
    border: 1px solid $grey-750;
    border-radius: 25rem;
    padding: 0.8rem 1.5rem;
    margin: 1.3rem 0;
    color: $grey-100;
  }

  .button-container {
    display: flex;
    justify-content: center;

    button {
      display: flex;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      background-color: $red;
      color: white;
      border: 1px solid $red;
      width: 100px;
      border-radius: 25rem;
      font-weight: 500;
      margin: 2rem;
    }
  }
}
